<script setup lang="ts">
	
	
	import PhoneInput from "~/components/UI/Inputs/PhoneInput.vue";
	
	
	const {useSubmitMenuIsOpen, useCurrentSubmitType} = useModal();
	
	const {fetchForCallData} = useTelegram();
	
	const modalSubmitIsOpen = useSubmitMenuIsOpen();
	const currentSubmitType = useCurrentSubmitType();
	
	const {isMobileOrTablet} = useDevice();
	const formDataDefault = {
		phone: '',
		name: ''
	}
	
	let formDataRequest = reactive({...formDataDefault});
	

	const masks = ref({
		modelValue: 'YYYY-MM-DD',
	});
	const today = new Date();
	const rules = ref({
		hours: { min: 12, max: 20 },
		minutes: { interval: 5 },
	});
	const btnLoading = ref(false)
	
	const chosenDate = ref(new Date());
	const submitTitles = {
		'for-call': 'Заказать звонок',
		'for-flat': 'Заявка на аппартаменты',
		'for-show': 'Записаться на показ'
	}
	
	const submitTitle = computed(() => submitTitles[currentSubmitType.value || 'for-call'])
	
	const type = ref<number>(1);
	const btnText = ref('Отправить заявку');
	
	
	
	async function sendRequest() {
		const {phone, name} = formDataRequest;
		btnLoading.value = true;
		try {
			
			await fetchForCallData(phone, name,currentSubmitType.value || 'for-call', currentSubmitType.value ==='for-show' ? chosenDate.value : null);
			setTimeout(() => {
				btnLoading.value = false;
				btnText.value = 'Заявка отправлена';
				formDataRequest = {...formDataDefault};
			}, 50)
			setTimeout(() => {
				modalSubmitIsOpen.value = false;
			}, 700)
		} catch (e) {
			console.log(e)
		}
	}
	
	
</script>

<template>
<ModalLayout
	v-model="modalSubmitIsOpen"
	class="modal"
>
	<template #title>
		<h3 class="modal__title" v-if="!isMobileOrTablet">{{submitTitle}}</h3>
	</template>
	
	
	<h2 class="modal__title mb-2" v-if="isMobileOrTablet">{{submitTitle}}</h2>
	
	<form class="modal__form" @submit.prevent="sendRequest">
		<p class="modal__text">
			Менеджер перезвонит вам и ответит на вопросы
		</p>
		<v-text-field
			label="Как Вас зовут?*"
			required
			v-model="formDataRequest.name"
		></v-text-field>
		<PhoneInput
			v-model="formDataRequest.phone"
			required
		/>
		<div class="modal__date" v-if="currentSubmitType === 'for-show'">
			<p>На какое число хотели бы записаться?</p>
			<DatePicker
				v-model="chosenDate"
				:min-date="today"
				:masks="masks"
				transparent
				borderless
			/>
		</div>
		
		<v-btn
			block
			class="modal__btn"
			
			:loading="btnLoading"
			type="submit"
		>
			{{ btnText }}
		</v-btn>
		
		<div class="menu__office office">
			<h4 class="office__title">
				Офис продаж на объекте
			</h4>
			<div class="office__address">
				<v-icon color="#FAAC37">mdi-phone</v-icon>
				<a class="office__phone" href="tel:+7 (938) 450-99-88">
					+7 (938) 450-99-88
				</a>
			</div>
			
			<div class="office__address">
				<v-icon color="#FAAC37">mdi-map-marker</v-icon>
				<span>г. Сочи, Цюрупы 13Д</span>
			</div>
			<v-btn class="office__btn" href="https://yandex.ru/maps/-/CDBRrSNJ" variant="text">Проложить маршрут</v-btn>
			
			<div class="office__time">Без выходных: с 10:00 до 18:00 </div>
		</div>
	</form>

</ModalLayout>

</template>

<style scoped lang="scss">

.office {
	
	border-radius: 7px;
	margin-top: 48px;
	background: $bg;
	padding: 16px;
	
	&__btn {
		margin-left: -16px;
	}
	
	&__title {
		color: $text-main;
		font-size: 20px;
		margin-bottom: 8px;
	}
	
	&__address {
		color: $text-main;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		margin-top: 16px;
		margin-bottom: 16px;
	}
	
	
	&__time {
		color: #818487;
		font-size: 14px;
		margin-top: 8px;
	}
	&__phone {
		display: block;
		color: $text-main;
		
		font-size: 16px;
		
		font-style: normal;
		
		text-decoration: none;
		
	}
	
}

.modal {
	&__form {
		padding: 8px 24px 48px 24px;
	}
	
	&__title {
		margin-left: 24px;
	}
	
	&__text {
		margin-bottom: 24px;
	}
	
	&__date {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	
}

</style>