import {Galary} from "~/data/GalaryData";

interface ICurrentPhoto {
	index: number,
	category: 'studio' | 'one' | 'two' | 'complex'
}
export default () => {
	const useCurrentPhoto = () => useState<ICurrentPhoto>(() => ({
		index: 1,
		category: 'complex'
	}));
	const useModalIsOpen = () => useState<boolean>(() => false)
	const currentPhoto = useCurrentPhoto();
	const modalIsOpen = useModalIsOpen();

	function openGalleryModal(photo: ICurrentPhoto) {
		currentPhoto.value = photo;
		modalIsOpen.value = true
	}

	return {
		useCurrentPhoto,
		openGalleryModal,
		useModalIsOpen
	}
}