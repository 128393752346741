<script setup lang="ts">

import ModalLayout from "~/components/Modal/ModalLayout.vue";

const {useBurgerMenuIsOpen, openSubmitMenu} = useModal();

const burgerMenuIsOpen = useBurgerMenuIsOpen();

const route = useRoute();
const router = useRouter();



const links = [
	{
		href: 'index',
		text: 'О нас',
		icon: 'nav/home'
	},
	{
		href: 'flats',
		text: 'Квартиры',
		icon: 'nav/flat'
	},
	{
		href: 'gallery',
		text: 'Галлерея',
		icon: 'nav/gallery'
	},
	{
		href: 'documents',
		text: 'Документы',
		icon: 'nav/docs'
	},
	{
		href: 'contacts',
		text: 'Контакты',
		icon: 'nav/contacts'
	},
];


const computedLinks = computed(() => {
	return links.map(link => ({
		...link,
		isActive: route.fullPath.indexOf(link.href) > 0 || route.name === link.href
	}))
});

</script>

<template>
	<ModalLayout
		v-model="burgerMenuIsOpen"
		class="menu"
	>
		<template #title>
			<div class="menu__header">
				<NuxtIcon name="riverplaza" filled class="logo"/>
				<h2 class="menu__title">Ривер Плаза</h2>
			</div>
			
		</template>
		<div class="menu__body">
			<v-card
				v-for="{isActive, href, text, icon} of computedLinks"
				:class="['menu__link', {
	                'active': isActive
	            }]"
				v-ripple
				color="rgba(0,0,0,0)"
				:href="href === 'index' ? '/' : '/' + href"
				elevation="0"
			>
				<nuxt-icon :name="icon" filled class="menu__icon"/>
				<span class="menu__text">{{text}}</span>
			</v-card>
			
			<div class="menu__submit submit">
				<div class="submit__info">
					Контактный телефон
				</div>
				<a class="submit__phone" href="tel:+7 (938) 450-99-88">
					+7 (938) 450-99-88
				</a>
				<VBtn @click="openSubmitMenu('for-call')">
					Заказать звонок
				</VBtn>
				<VBtn variant="text" @click="openSubmitMenu('for-show')">
					Записаться на показ
				</VBtn>
			</div>
			
			<div class="menu__office office">
				<h4 class="office__title">
					Офис продаж на объекте
				</h4>
				<div class="office__address">
					<nuxt-icon name="main-page/location" filled />
					<span>Цюрупы 13Д</span>
				</div>
				<v-btn class="office__btn" href="https://yandex.ru/maps/-/CDBRrSNJ" variant="text">Проложить маршрут</v-btn>
				
				<div class="office__time">Без выходных: с 10:00 до 18:00 </div>
			</div>
		</div>
		
	</ModalLayout>
</template>

<style scoped lang="scss">

.office {
	
	border-radius: 7px;
	margin-top: 24px;
	background: $bg;
	padding: 16px;
	
	&__btn {
		margin-left: -16px;
	}
	
	&__title {
		color: $text-main;
		font-size: 20px;
		margin-bottom: 8px;
	}
	
	&__address {
		color: #333D46;
		font-size: 20px;
		display: flex;
		align-items: center;
		gap: 8px;
	}
	
	&__time {
		color: #818487;
		font-size: 14px;
		margin-top: 8px;
	}
	
}

.submit {
	padding: 16px;
	border-radius: 7px;
	border: 1px solid $light-gray;
	text-align: center;
	margin-top: 24px;
	
	&__info {
		color: #818487;
		
	}
	
	&__phone {
		display: block;
		color: $text-main;
		font-family: "CasperBold", sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		text-decoration: none;
		margin-top: 8px;
	}
	
	button {
		margin-top: 8px;
		width: 100%;
	}
}


.menu {
	
	&__header {
		display: flex;
		align-items: center;
		margin-left: 16px;
		gap: 8px;
	}
	
	&__title {
		
		color: $text-main;
		margin-top: 3px;
		font-family: 'CasperBold', sans-serif;
		font-weight: normal;
		font-size: 20px;
		
	}
	
	&__description {
		margin-top: 48px;
		font-weight: 500;
		color: #B4B4B4;
		margin-bottom: 16px;
	}
	
	
	&__body {
		margin: 16px;
	}
	
	&__btn {
		border: 2px solid $accent;
		border-radius: 50px;
		width: fit-content;
		display: flex;
		align-items: center;
		span {
			color: $accent;
			font-size: 14px;
			font-weight: 500;
			padding: 6px 6px 6px 16px;
		}
		
		.btn__icon {
			height: 24px;
			margin-right: 8px;
		}
	}
	
	
	&__link {
		margin-bottom: 8px;
		padding: 16px 16px;
		display: flex;
		align-items: center;
		border-radius: 4px;
		gap: 22px;
		span {
			font-size: 20px;
			color: $text-main;
			font-family: 'CasperBold', sans-serif;
			font-weight: 500;
		}
		
		&.active span {
			color: $accent;
		}
	}
}
</style>