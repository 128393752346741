<script setup lang="ts">
	import { Galary } from "~/data/GalaryData";
	import useGallery from "~/composables/useGallery";
	
	const { isMobileOrTablet } = useDevice();
	
	const { useCurrentPhoto, openGalleryModal, useModalIsOpen } = useGallery();
	const currentPhoto = useCurrentPhoto();
	const modalIsOpen = useModalIsOpen();
	
	const photos = computed(() => Galary[currentPhoto.value.category]);
	
	const currentPhotoIndex = ref(1);
	watch(currentPhoto, () => {
		currentPhotoIndex.value = currentPhoto.value.index;
	})

</script>

<template>
	<ModalLayout
		v-model="modalIsOpen"
		:is-gallery="true"
	>
		<template #title>
			<h3 class="carousel__title">Галлерея</h3>
		</template>
		<div :class="['carousel', {
			'carousel_mobile': isMobileOrTablet
		}]">
			<VCarousel
				v-model="currentPhotoIndex"
				hide-delimiters
				class="carousel__slider"
			>
				<VCarouselItem
					v-for="{urlFull, index} of photos"
					:value="index"
					:key="index"
				>
					<v-img :src="urlFull" width="100%" height="100%" />
				</VCarouselItem>
			</VCarousel>
			<div class="overflow" >
				<v-item-group
					v-model="currentPhotoIndex"
					show-arrows
					class="thumbs"
					v-if="!isMobileOrTablet"
				>
					<v-item
						v-for="photo of photos"
						:value="photo.index"
						:key="photo.index"
						v-slot="{ isSelected, toggle, selectedClass }"
						
					>
						<v-card v-ripple
						        @click="currentPhotoIndex = photo.index"
						        :class="['thumb', {
									'thumb_active': isSelected
								}]"
						  
						>
							<v-img  :src="photo.urlPreview" aspect-ratio="1" cover height="130px"></v-img>
						</v-card>
					</v-item>
				</v-item-group>
				<v-slide-group
					v-model="currentPhotoIndex"
					show-arrows
					class="slider"
					v-else
				>
					<v-slide-group-item
						v-for="photo of photos"
						:value="photo.index"
						:key="photo.index"
						v-slot="{ isSelected, toggle, selectedClass }"
						
					>
						<v-card v-ripple
						        @click="currentPhotoIndex = photo.index"
						        :class="['thumb', {
									'thumb_active': isSelected
								}]"
						
						>
							<v-img  :src="photo.urlPreview" aspect-ratio="1" cover height="60px" width="60px"></v-img>
						</v-card>
					</v-slide-group-item>
				</v-slide-group>
			</div>
			
			
			
		</div>
		
	</ModalLayout>

</template>

<style scoped lang="scss">

.carousel {
	padding: 0 16px;
	margin-top: 16px;
	&__title {
		margin-left: 24px;
		
	}
}

.slider {
	margin-left: -16px;
	margin-right: -16px;
	margin-top: 32px;
}

.thumb {
	
	margin: 6px;
	border: 2px solid rgba(0,0,0,0);
	transition: .3s ease;
	&_active {
		border: 2px solid $accent;
	}
}

.carousel_mobile {
	margin-top: 32px;
	.thumbs {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	}
	
	.carousel__slider {
		height: 350px !important;
	}
}


.thumbs {
	margin-top: 32px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-template-rows: auto;
	overflow-y: auto;
	max-height: 400px;
	
}

</style>