export default () => {

	type SubmitTypes = 'for-call' | 'for-flat' | 'for-show'
	const useBurgerMenuIsOpen = () => useState<boolean>(() => false);
	const useSubmitMenuIsOpen = () => useState<boolean>(() => false);
	const useCurrentSubmitType = () => useState<SubmitTypes | null>(() => null);

	const currentSubmitType = useCurrentSubmitType();
	const burgerMenuIsOpen = useBurgerMenuIsOpen();
	const submitMenuIsOpen = useSubmitMenuIsOpen();

	const isModalOpen = () => burgerMenuIsOpen.value && submitMenuIsOpen.value;

	const closeBurgerMenu = () => burgerMenuIsOpen.value = false;
	const openBurgerMenu = () => burgerMenuIsOpen.value = true;

	const closeSubmitMenu = () => {
		submitMenuIsOpen.value = false;
		currentSubmitType.value = null;
	}
	const openSubmitMenu = (submitType: SubmitTypes) => {
		currentSubmitType.value = submitType;
		submitMenuIsOpen.value = true;
	};

	return {
        useBurgerMenuIsOpen,
        useSubmitMenuIsOpen,

        closeBurgerMenu,
        openBurgerMenu,

        closeSubmitMenu,
        openSubmitMenu,

		isModalOpen,
		useCurrentSubmitType
    }
}