import { default as loginvKHrOoxzDtMeta } from "/var/www/html/source/pages/admin/login.vue?macro=true";
import { default as reservedyztlYPSWdcMeta } from "/var/www/html/source/pages/admin/reserved.vue?macro=true";
import { default as search0GIHGCoTPDMeta } from "/var/www/html/source/pages/admin/search.vue?macro=true";
import { default as contactsCPK3R8PvcrMeta } from "/var/www/html/source/pages/contacts.vue?macro=true";
import { default as documentsVs4fLryW0LMeta } from "/var/www/html/source/pages/documents.vue?macro=true";
import { default as _91id_93rfAHvuanVsMeta } from "/var/www/html/source/pages/flat/[id].vue?macro=true";
import { default as indexqirOsfSrQmMeta } from "/var/www/html/source/pages/flats/index.vue?macro=true";
import { default as listq7BGY8eplTMeta } from "/var/www/html/source/pages/flats/list.vue?macro=true";
import { default as designk56hnkYCSjMeta } from "/var/www/html/source/pages/gallery/design.vue?macro=true";
import { default as indextM25NUPX8sMeta } from "/var/www/html/source/pages/gallery/index.vue?macro=true";
import { default as indexmCmltuiFxvMeta } from "/var/www/html/source/pages/index.vue?macro=true";
export default [
  {
    name: loginvKHrOoxzDtMeta?.name ?? "admin-login",
    path: loginvKHrOoxzDtMeta?.path ?? "/admin/login",
    meta: loginvKHrOoxzDtMeta || {},
    alias: loginvKHrOoxzDtMeta?.alias || [],
    redirect: loginvKHrOoxzDtMeta?.redirect,
    component: () => import("/var/www/html/source/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: reservedyztlYPSWdcMeta?.name ?? "admin-reserved",
    path: reservedyztlYPSWdcMeta?.path ?? "/admin/reserved",
    meta: reservedyztlYPSWdcMeta || {},
    alias: reservedyztlYPSWdcMeta?.alias || [],
    redirect: reservedyztlYPSWdcMeta?.redirect,
    component: () => import("/var/www/html/source/pages/admin/reserved.vue").then(m => m.default || m)
  },
  {
    name: search0GIHGCoTPDMeta?.name ?? "admin-search",
    path: search0GIHGCoTPDMeta?.path ?? "/admin/search",
    meta: search0GIHGCoTPDMeta || {},
    alias: search0GIHGCoTPDMeta?.alias || [],
    redirect: search0GIHGCoTPDMeta?.redirect,
    component: () => import("/var/www/html/source/pages/admin/search.vue").then(m => m.default || m)
  },
  {
    name: contactsCPK3R8PvcrMeta?.name ?? "contacts",
    path: contactsCPK3R8PvcrMeta?.path ?? "/contacts",
    meta: contactsCPK3R8PvcrMeta || {},
    alias: contactsCPK3R8PvcrMeta?.alias || [],
    redirect: contactsCPK3R8PvcrMeta?.redirect,
    component: () => import("/var/www/html/source/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: documentsVs4fLryW0LMeta?.name ?? "documents",
    path: documentsVs4fLryW0LMeta?.path ?? "/documents",
    meta: documentsVs4fLryW0LMeta || {},
    alias: documentsVs4fLryW0LMeta?.alias || [],
    redirect: documentsVs4fLryW0LMeta?.redirect,
    component: () => import("/var/www/html/source/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rfAHvuanVsMeta?.name ?? "flat-id",
    path: _91id_93rfAHvuanVsMeta?.path ?? "/flat/:id()",
    meta: _91id_93rfAHvuanVsMeta || {},
    alias: _91id_93rfAHvuanVsMeta?.alias || [],
    redirect: _91id_93rfAHvuanVsMeta?.redirect,
    component: () => import("/var/www/html/source/pages/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqirOsfSrQmMeta?.name ?? "flats",
    path: indexqirOsfSrQmMeta?.path ?? "/flats",
    meta: indexqirOsfSrQmMeta || {},
    alias: indexqirOsfSrQmMeta?.alias || [],
    redirect: indexqirOsfSrQmMeta?.redirect,
    component: () => import("/var/www/html/source/pages/flats/index.vue").then(m => m.default || m)
  },
  {
    name: listq7BGY8eplTMeta?.name ?? "flats-list",
    path: listq7BGY8eplTMeta?.path ?? "/flats/list",
    meta: listq7BGY8eplTMeta || {},
    alias: listq7BGY8eplTMeta?.alias || [],
    redirect: listq7BGY8eplTMeta?.redirect,
    component: () => import("/var/www/html/source/pages/flats/list.vue").then(m => m.default || m)
  },
  {
    name: designk56hnkYCSjMeta?.name ?? "gallery-design",
    path: designk56hnkYCSjMeta?.path ?? "/gallery/design",
    meta: designk56hnkYCSjMeta || {},
    alias: designk56hnkYCSjMeta?.alias || [],
    redirect: designk56hnkYCSjMeta?.redirect,
    component: () => import("/var/www/html/source/pages/gallery/design.vue").then(m => m.default || m)
  },
  {
    name: indextM25NUPX8sMeta?.name ?? "gallery",
    path: indextM25NUPX8sMeta?.path ?? "/gallery",
    meta: indextM25NUPX8sMeta || {},
    alias: indextM25NUPX8sMeta?.alias || [],
    redirect: indextM25NUPX8sMeta?.redirect,
    component: () => import("/var/www/html/source/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexmCmltuiFxvMeta?.name ?? "index",
    path: indexmCmltuiFxvMeta?.path ?? "/",
    meta: indexmCmltuiFxvMeta || {},
    alias: indexmCmltuiFxvMeta?.alias || [],
    redirect: indexmCmltuiFxvMeta?.redirect,
    component: () => import("/var/www/html/source/pages/index.vue").then(m => m.default || m)
  }
]