
function formatDate(inputDate) {
	let date;

	// Проверяем, является ли входной параметр строкой или объектом Date
	if (typeof inputDate === 'string') {
		date = new Date(inputDate);
	} else if (inputDate instanceof Date) {
		date = inputDate;
	} else {
		// Если входной параметр неправильного типа, возвращаем сообщение об ошибке
		return 'Invalid input';
	}

	// Форматируем дату в красивый вид
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	return date.toLocaleDateString('ru-RU', options);
}
export default () => {
	const token = '6951595299:AAG4iheVqsWK9QYvI3_LxbEjd4q5GAjjfT0\n'
	const chatID = -1002000573577;
	type SubmitTypes = 'for-call' | 'for-flat' | 'for-show' | 'for-docs'
	const getUrl = (text: string): string => `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatID}&text=${text}`
	// @ts-ignore
	const fetchForCallData = async (phone: string, name: string, type: SubmitTypes, date?: Date | null = null) => {
		const submitTitles = {
			'for-call': 'на звонок',
			'for-flat': 'на аппартаменты',
			'for-show': 'на показ',
			'for-docs': 'на документы'
		}
		let text = `Клиент оставил заявку ${submitTitles[type]}: %0A👶Имя: ${name} %0A📱Номер телефона: +7 ${phone} `
		if (date) {
			text += `%0AДата: ${formatDate(date)}`
		}
		const url = getUrl(text);
		return useFetch(url)
	}
	return {
		fetchForCallData,
	}

}