import {Ripple} from 'vuetify/directives/ripple'

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"defaults":{"VBtn":{"variant":"flat","color":"rgba(250, 172, 55, 0.7)","theme":"dark"},"VTextField":{"variant":"solo","bgColor":"#F0F3F7","density":"comfortable"},"VSelect":{"hideDetails":true,"variant":"solo","density":"compact"}},"ssr":true}')
  options.directives = {Ripple}
  
  
  
  return options
}

