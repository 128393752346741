interface IPhoto {
	urlFull: string,
	urlPreview: string,
	index: number
}

interface IGallery {
	one: IPhoto[],
	two: IPhoto[],
	studio: IPhoto[],
	complex: IPhoto[]
}


export const Galary: IGallery = {
	one: [
		{
			urlFull: '/gallery/design/one-room/0c0583e1-f50e-4fb0-a2a1-cdc3f81c7a51.webp',
			urlPreview: '/gallery/design/one-room/0c0583e1-f50e-4fb0-a2a1-cdc3f81c7a51-compressed.webp',
			index: 1
		},
		{
			urlFull: '/gallery/design/one-room/6519a259-c8b5-4f20-8d52-e56c96fdbbb2.webp',
			urlPreview: '/gallery/design/one-room/6519a259-c8b5-4f20-8d52-e56c96fdbbb2-compressed.webp',
			index: 2
		},
		{
			urlFull: '/gallery/design/one-room/b5983ae5-f1b3-4a28-8a97-d1e56649d15b.webp',
			urlPreview: '/gallery/design/one-room/b5983ae5-f1b3-4a28-8a97-d1e56649d15b-compressed.webp',
			index: 3
		},
		{
			urlFull: '/gallery/design/one-room/7ab2b332-e4f5-47c7-8451-06c5c810e943.webp',
			urlPreview: '/gallery/design/one-room/7ab2b332-e4f5-47c7-8451-06c5c810e943-compressed.webp',
			index: 4
		},
		{
			urlFull: '/gallery/design/one-room/5a94b61e-ce60-4308-bda9-71d763235074.webp',
			urlPreview: '/gallery/design/one-room/5a94b61e-ce60-4308-bda9-71d763235074-compressed.webp',
			index: 5
		},
		{
			urlFull: '/gallery/design/one-room/052eeff0-cedb-43ed-b993-2562fbd617d1.webp',
			urlPreview: '/gallery/design/one-room/052eeff0-cedb-43ed-b993-2562fbd617d1-compressed.webp',
			index: 6
		},
		{
			urlFull: '/gallery/design/one-room/506b5d62-954d-471b-ad13-ca21d4ea3f6b.webp',
			urlPreview: '/gallery/design/one-room/506b5d62-954d-471b-ad13-ca21d4ea3f6b-compressed.webp',
			index: 7
		},
		{
			urlFull: '/gallery/design/one-room/24e441f6-5b26-4ef8-b6bb-ffa84769a68d.webp',
			urlPreview: '/gallery/design/one-room/24e441f6-5b26-4ef8-b6bb-ffa84769a68d-compressed.webp',
			index: 8
		},
		{
			urlFull: '/gallery/design/one-room/6afa82ec-1a10-4219-ae11-62a689a2b310.webp',
			urlPreview: '/gallery/design/one-room/6afa82ec-1a10-4219-ae11-62a689a2b310-compressed.webp',
			index: 9
		},
		{
			urlFull: '/gallery/design/one-room/e19dc26e-80d8-4ded-a7ed-5758801e9dd7.webp',
			urlPreview: '/gallery/design/one-room/e19dc26e-80d8-4ded-a7ed-5758801e9dd7-compressed.webp',
			index: 10
		},
		{
			urlFull: '/gallery/design/one-room/081a0e93-0f04-48bd-a44d-f433bd01cbcd.webp',
			urlPreview: '/gallery/design/one-room/081a0e93-0f04-48bd-a44d-f433bd01cbcd-compressed.webp',
			index: 11
		},
		{
			urlFull: '/gallery/design/one-room/e0a95c4a-84df-4866-9327-a08c3d0179e2.webp',
			urlPreview: '/gallery/design/one-room/e0a95c4a-84df-4866-9327-a08c3d0179e2-compressed.webp',
			index: 12
		},
		{
			urlFull: '/gallery/design/one-room/48910809-f43a-4c40-860c-f95070a33fe3.webp',
			urlPreview: '/gallery/design/one-room/48910809-f43a-4c40-860c-f95070a33fe3-compressed.webp',
			index: 13
		}

	],
	two: [
		{
			urlFull: '/gallery/design/two-room/1b2a03e2-f8fa-47d1-868f-9f6a54191e1f.webp',
			urlPreview: '/gallery/design/two-room/1b2a03e2-f8fa-47d1-868f-9f6a54191e1f-compressed.webp',
			index: 1
		},
		{
			urlFull: '/gallery/design/two-room/ee4ea529-22de-44bb-83f2-e4087503aada.webp',
			urlPreview: '/gallery/design/two-room/ee4ea529-22de-44bb-83f2-e4087503aada-compressed.webp',
			index: 2
		},
		{
			urlFull: '/gallery/design/two-room/3d3bc9f0-f8be-4efb-bd66-50d4e83f8fd2.webp',
			urlPreview: '/gallery/design/two-room/3d3bc9f0-f8be-4efb-bd66-50d4e83f8fd2-compressed.webp',
			index: 3
		},
		{
			urlFull: '/gallery/design/two-room/d0925a6a-b557-49e7-87cf-d1c027b78ca6.webp',
			urlPreview: '/gallery/design/two-room/d0925a6a-b557-49e7-87cf-d1c027b78ca6-compressed.webp',
			index: 4
		},
		{
			urlFull: '/gallery/design/two-room/990e0bfc-06ae-4521-b28d-bff1a02df1d1.webp',
			urlPreview: '/gallery/design/two-room/990e0bfc-06ae-4521-b28d-bff1a02df1d1-compressed.webp',
			index: 5
		},
		{
			urlFull: '/gallery/design/two-room/3e5854b4-2a4b-482f-878f-fb4fc1f7df7c.webp',
			urlPreview: '/gallery/design/two-room/3e5854b4-2a4b-482f-878f-fb4fc1f7df7c-compressed.webp',
			index: 6
		},
		{
			urlFull: '/gallery/design/two-room/c5f2dd7d-a336-4f79-a007-59749a3cec9e.webp',
			urlPreview: '/gallery/design/two-room/c5f2dd7d-a336-4f79-a007-59749a3cec9e-compressed.webp',
			index: 7
		},
		{
			urlFull: '/gallery/design/two-room/00e471a3-073a-4e02-b214-ecceabb1ff74.webp',
			urlPreview: '/gallery/design/two-room/00e471a3-073a-4e02-b214-ecceabb1ff74-compressed.webp',
			index: 8
		},
		{
			urlFull: '/gallery/design/two-room/f116b709-b1a7-4999-8a60-e611f28b3e33.webp',
			urlPreview: '/gallery/design/two-room/f116b709-b1a7-4999-8a60-e611f28b3e33-compressed.webp',
			index: 9
		},
		{
			urlFull: '/gallery/design/two-room/86365851-a0a2-4261-a839-08c6bd1246b6.webp',
			urlPreview: '/gallery/design/two-room/86365851-a0a2-4261-a839-08c6bd1246b6-compressed.webp',
			index: 10
		},
		{
			urlFull: '/gallery/design/two-room/c26e75f6-731d-4510-943d-ad1c683434a5.webp',
			urlPreview: '/gallery/design/two-room/c26e75f6-731d-4510-943d-ad1c683434a5-compressed.webp',
			index: 11
		},
		{
			urlFull: '/gallery/design/two-room/a12fcdcb-5218-49f5-8c48-36310da97608.webp',
			urlPreview: '/gallery/design/two-room/a12fcdcb-5218-49f5-8c48-36310da97608-compressed.webp',
			index: 12
		},
		{
			urlFull: '/gallery/design/two-room/7b8dc977-1900-4064-8e51-1bc2d1692ecc.webp',
			urlPreview: '/gallery/design/two-room/7b8dc977-1900-4064-8e51-1bc2d1692ecc-compressed.webp',
			index: 13
		},
		{
			urlFull: '/gallery/design/two-room/afa613ad-cbfb-480c-b892-07c424359259.webp',
			urlPreview: '/gallery/design/two-room/afa613ad-cbfb-480c-b892-07c424359259-compressed.webp',
			index: 14
		},
		{
			urlFull: '/gallery/design/two-room/1b212218-7108-44a0-b505-1eb801900035.webp',
			urlPreview: '/gallery/design/two-room/1b212218-7108-44a0-b505-1eb801900035-compressed.webp',
			index: 15
		},
		{
			urlFull: '/gallery/design/two-room/2a1d8771-f05f-4736-b1a2-e6a5e2ede7a2.webp',
			urlPreview: '/gallery/design/two-room/2a1d8771-f05f-4736-b1a2-e6a5e2ede7a2-compressed.webp',
			index: 16
		},
		{
			urlFull: '/gallery/design/two-room/ad50aa79-e4c5-4a71-9fc3-e0d5c48b1982.webp',
			urlPreview: '/gallery/design/two-room/ad50aa79-e4c5-4a71-9fc3-e0d5c48b1982-compressed.webp',
			index: 17
		},
		{
			urlFull: '/gallery/design/two-room/b218d0b7-f6b5-4d90-bb49-03e2a0ad5ce4.webp',
			urlPreview: '/gallery/design/two-room/b218d0b7-f6b5-4d90-bb49-03e2a0ad5ce4-compressed.webp',
			index: 18
		}

	],
	studio: [
		{
			urlFull: '/gallery/design/studio/240a28ba-10bf-418b-bfbc-3bcad6a0caa6.webp',
			urlPreview: '/gallery/design/studio/240a28ba-10bf-418b-bfbc-3bcad6a0caa6-compressed.webp',
			index: 1
		},
		{
			urlFull: '/gallery/design/studio/c1a4d283-1c1d-44e1-b3eb-6b94e665bbd5.webp',
			urlPreview: '/gallery/design/studio/c1a4d283-1c1d-44e1-b3eb-6b94e665bbd5-compressed.webp',
			index: 2
		},
		{
			urlFull: '/gallery/design/studio/30a242b2-b915-4036-83d6-31390827b685.webp',
			urlPreview: '/gallery/design/studio/30a242b2-b915-4036-83d6-31390827b685-compressed.webp',
			index: 3
		},
		{
			urlFull: '/gallery/design/studio/5caa32b4-b107-4c04-bad2-2846d196f8c9.webp',
			urlPreview: '/gallery/design/studio/5caa32b4-b107-4c04-bad2-2846d196f8c9-compressed.webp',
			index: 4
		},
		{
			urlFull: '/gallery/design/studio/b047c580-877d-4db1-9031-d2ceb578e702.webp',
			urlPreview: '/gallery/design/studio/b047c580-877d-4db1-9031-d2ceb578e702-compressed.webp',
			index: 5
		},
		{
			urlFull: '/gallery/design/studio/473b85b7-f86c-4c4f-827e-8a1a794b8cc9.webp',
			urlPreview: '/gallery/design/studio/473b85b7-f86c-4c4f-827e-8a1a794b8cc9-compressed.webp',
			index: 6
		},
		{
			urlFull: '/gallery/design/studio/20a21f94-d9f6-43ff-8397-73cad75faba8.webp',
			urlPreview: '/gallery/design/studio/20a21f94-d9f6-43ff-8397-73cad75faba8-compressed.webp',
			index: 7
		},
		{
			urlFull: '/gallery/design/studio/1a1b554d-f059-47fe-9b76-34b02b7c9d9d.webp',
			urlPreview: '/gallery/design/studio/1a1b554d-f059-47fe-9b76-34b02b7c9d9d-compressed.webp',
			index: 8
		},
		{
			urlFull: '/gallery/design/studio/14ee2e30-cb96-455f-ae8e-da8e98f29639.webp',
			urlPreview: '/gallery/design/studio/14ee2e30-cb96-455f-ae8e-da8e98f29639-compressed.webp',
			index: 9
		},
		{
			urlFull: '/gallery/design/studio/a9f4a985-65cc-426a-85c7-69ffdaac93f2.webp',
			urlPreview: '/gallery/design/studio/a9f4a985-65cc-426a-85c7-69ffdaac93f2-compressed.webp',
			index: 10
		},
		{
			urlFull: '/gallery/design/studio/63f0e86c-10a1-41c5-b2e4-4d40a81a1591.webp',
			urlPreview: '/gallery/design/studio/63f0e86c-10a1-41c5-b2e4-4d40a81a1591-compressed.webp',
			index: 11
		},
		{
			urlFull: '/gallery/design/studio/76136275-9684-4f90-b3b7-7ee54911b413.webp',
			urlPreview: '/gallery/design/studio/76136275-9684-4f90-b3b7-7ee54911b413-compressed.webp',
			index: 12
		},
		{
			urlFull: '/gallery/design/studio/276155bc-3b9e-4e25-a0a6-be214529712e.webp',
			urlPreview: '/gallery/design/studio/276155bc-3b9e-4e25-a0a6-be214529712e-compressed.webp',
			index: 13
		},
		{
			urlFull: '/gallery/design/studio/f50c20d0-357a-4b49-8d36-bc4fd7f26a5d.webp',
			urlPreview: '/gallery/design/studio/f50c20d0-357a-4b49-8d36-bc4fd7f26a5d-compressed.webp',
			index: 14
		},
		{
			urlFull: '/gallery/design/studio/671b11c3-4406-4cc9-a1c9-a665c6785893.webp',
			urlPreview: '/gallery/design/studio/671b11c3-4406-4cc9-a1c9-a665c6785893-compressed.webp',
			index: 15
		},
		{
			urlFull: '/gallery/design/studio/62b2096d-785b-4ac5-8ae3-a400cdcf1cc4.webp',
			urlPreview: '/gallery/design/studio/62b2096d-785b-4ac5-8ae3-a400cdcf1cc4-compressed.webp',
			index: 16
		},
		{
			urlFull: '/gallery/design/studio/650ef2b9-98df-4c15-8f14-46233ace12d1.webp',
			urlPreview: '/gallery/design/studio/650ef2b9-98df-4c15-8f14-46233ace12d1-compressed.webp',
			index: 17
		},
		{
			urlFull: '/gallery/design/studio/64789a6f-4e9c-4d48-8a95-20a46a812af2.webp',
			urlPreview: '/gallery/design/studio/64789a6f-4e9c-4d48-8a95-20a46a812af2-compressed.webp',
			index: 18
		},
		{
			urlFull: '/gallery/design/studio/9ad3b9c3-63d1-455c-8324-d0cf832892c1.webp',
			urlPreview: '/gallery/design/studio/9ad3b9c3-63d1-455c-8324-d0cf832892c1-compressed.webp',
			index: 19
		},
		{
			urlFull: '/gallery/design/studio/17ceef90-7d2c-4355-aa16-a2b2c47575d8.webp',
			urlPreview: '/gallery/design/studio/17ceef90-7d2c-4355-aa16-a2b2c47575d8-compressed.webp',
			index: 20
		},
		{
			urlFull: '/gallery/design/studio/18f3aa54-b705-48f7-a074-69a253841e14.webp',
			urlPreview: '/gallery/design/studio/18f3aa54-b705-48f7-a074-69a253841e14-compressed.webp',
			index: 21
		},
		{
			urlFull: '/gallery/design/studio/3c35e308-b9d9-490c-9be3-112ac0e857e4.webp',
			urlPreview: '/gallery/design/studio/3c35e308-b9d9-490c-9be3-112ac0e857e4-compressed.webp',
			index: 22
		},
		{
			urlFull: '/gallery/design/studio/476e5f16-2907-48ce-9cd1-e3ce529fb7b2.webp',
			urlPreview: '/gallery/design/studio/476e5f16-2907-48ce-9cd1-e3ce529fb7b2-compressed.webp',
			index: 23
		}

	],
	complex: [
		{
			urlFull: '/gallery/complex/d14e3716-b44b-44c1-85d3-b91b8dcb2cee.webp',
			urlPreview: '/gallery/complex/d14e3716-b44b-44c1-85d3-b91b8dcb2cee-compressed.webp',
			index: 41
		},
		{
			urlFull: '/gallery/complex/fc2e7130-e05f-4f7c-883b-4eb8cb3ce37e.webp',
			urlPreview: '/gallery/complex/fc2e7130-e05f-4f7c-883b-4eb8cb3ce37e-compressed.webp',
			index: 42
		},
		{
			urlFull: '/gallery/complex/a74a4a46-c0c5-402c-9447-d8aa2d163525.webp',
			urlPreview: '/gallery/complex/a74a4a46-c0c5-402c-9447-d8aa2d163525-compressed.webp',
			index: 43
		},
		{
			urlFull: '/gallery/complex/10a9f061-cb9e-44b7-81a9-a9e7c52f8d80.webp',
			urlPreview: '/gallery/complex/10a9f061-cb9e-44b7-81a9-a9e7c52f8d80-compressed.webp',
			index: 44
		},
		{
			urlFull: '/gallery/complex/20b7795f-0802-4c88-8d7b-2bde2e315222.webp',
			urlPreview: '/gallery/complex/20b7795f-0802-4c88-8d7b-2bde2e315222-compressed.webp',
			index: 45
		},


		{
			urlFull: '/gallery/complex/DSC00726.webp',
			urlPreview: '/gallery/complex/DSC00726-compressed.webp',
			index: 1
		},
		{
			urlFull: '/gallery/complex/DSC00730.webp',
			urlPreview: '/gallery/complex/DSC00730-compressed.webp',
			index: 2
		},
		{
			urlFull: '/gallery/complex/DSC00737.webp',
			urlPreview: '/gallery/complex/DSC00737-compressed.webp',
			index: 3
		},
		{
			urlFull: '/gallery/complex/DSC00741.webp',
			urlPreview: '/gallery/complex/DSC00741-compressed.webp',
			index: 4
		},
		{
			urlFull: '/gallery/complex/DSC00744.webp',
			urlPreview: '/gallery/complex/DSC00744-compressed.webp',
			index: 5
		},
		{
			urlFull: '/gallery/complex/DSC02412.webp',
			urlPreview: '/gallery/complex/DSC02412-compressed.webp',
			index: 6
		},
		{
			urlFull: '/gallery/complex/DSC02415.webp',
			urlPreview: '/gallery/complex/DSC02415-compressed.webp',
			index: 7
		},
		{
			urlFull: '/gallery/complex/DSC02416.webp',
			urlPreview: '/gallery/complex/DSC02416-compressed.webp',
			index: 8
		},
		{
			urlFull: '/gallery/complex/DSC02430.webp',
			urlPreview: '/gallery/complex/DSC02430-compressed.webp',
			index: 9
		},
		{
			urlFull: '/gallery/complex/DSC02431.webp',
			urlPreview: '/gallery/complex/DSC02431-compressed.webp',
			index: 10
		},
		{
			urlFull: '/gallery/complex/DSC02459.webp',
			urlPreview: '/gallery/complex/DSC02459-compressed.webp',
			index: 11
		},
		{
			urlFull: '/gallery/complex/DSC09913.webp',
			urlPreview: '/gallery/complex/DSC09913-compressed.webp',
			index: 12
		},
		{
			urlFull: '/gallery/complex/DSC09914.webp',
			urlPreview: '/gallery/complex/DSC09914-compressed.webp',
			index: 13
		},
		{
			urlFull: '/gallery/complex/DSC09918.webp',
			urlPreview: '/gallery/complex/DSC09918-compressed.webp',
			index: 14
		},
		{
			urlFull: '/gallery/complex/DSC09923.webp',
			urlPreview: '/gallery/complex/DSC09923-compressed.webp',
			index: 15
		},
		{
			urlFull: '/gallery/complex/DSC09932.webp',
			urlPreview: '/gallery/complex/DSC09932-compressed.webp',
			index: 16
		},
		{
			urlFull: '/gallery/complex/DSC09939.webp',
			urlPreview: '/gallery/complex/DSC09939-compressed.webp',
			index: 17
		},
		{
			urlFull: '/gallery/complex/DSC09948.webp',
			urlPreview: '/gallery/complex/DSC09948-compressed.webp',
			index: 18
		},
		{
			urlFull: '/gallery/complex/DSC09949.webp',
			urlPreview: '/gallery/complex/DSC09949-compressed.webp',
			index: 19
		},
		{
			urlFull: '/gallery/complex/DSC09952.webp',
			urlPreview: '/gallery/complex/DSC09952-compressed.webp',
			index: 20
		},
		{
			urlFull: '/gallery/complex/DSC09956.webp',
			urlPreview: '/gallery/complex/DSC09956-compressed.webp',
			index: 21
		},
		{
			urlFull: '/gallery/complex/DSC09958.webp',
			urlPreview: '/gallery/complex/DSC09958-compressed.webp',
			index: 22
		},
		{
			urlFull: '/gallery/complex/DSC09959.webp',
			urlPreview: '/gallery/complex/DSC09959-compressed.webp',
			index: 23
		},
		{
			urlFull: '/gallery/complex/DSC09960.webp',
			urlPreview: '/gallery/complex/DSC09960-compressed.webp',
			index: 24
		},
		{
			urlFull: '/gallery/complex/DSC09962.webp',
			urlPreview: '/gallery/complex/DSC09962-compressed.webp',
			index: 25
		},
		{
			urlFull: '/gallery/complex/DSC09963.webp',
			urlPreview: '/gallery/complex/DSC09963-compressed.webp',
			index: 26
		},
		{
			urlFull: '/gallery/complex/DSC09966.webp',
			urlPreview: '/gallery/complex/DSC09966-compressed.webp',
			index: 27
		},
		{
			urlFull: '/gallery/complex/DSC09969.webp',
			urlPreview: '/gallery/complex/DSC09969-compressed.webp',
			index: 28
		},
		{
			urlFull: '/gallery/complex/DSC09971.webp',
			urlPreview: '/gallery/complex/DSC09971-compressed.webp',
			index: 29
		},
		{
			urlFull: '/gallery/complex/DSC09974.webp',
			urlPreview: '/gallery/complex/DSC09974-compressed.webp',
			index: 30
		},
		{
			urlFull: '/gallery/complex/IMG_1241.webp',
			urlPreview: '/gallery/complex/IMG_1241-compressed.webp',
			index: 31
		},
		{
			urlFull: '/gallery/complex/IMG_1245.webp',
			urlPreview: '/gallery/complex/IMG_1245-compressed.webp',
			index: 32
		},
		{
			urlFull: '/gallery/complex/IMG_1259.webp',
			urlPreview: '/gallery/complex/IMG_1259-compressed.webp',
			index: 33
		},
		{
			urlFull: '/gallery/complex/IMG_1260.webp',
			urlPreview: '/gallery/complex/IMG_1260-compressed.webp',
			index: 34
		},
		{
			urlFull: '/gallery/complex/IMG_1261.webp',
			urlPreview: '/gallery/complex/IMG_1261-compressed.webp',
			index: 35
		},
		{
			urlFull: '/gallery/complex/IMG_1268.webp',
			urlPreview: '/gallery/complex/IMG_1268-compressed.webp',
			index: 36
		},
		{
			urlFull: '/gallery/complex/IMG_1273.webp',
			urlPreview: '/gallery/complex/IMG_1273-compressed.webp',
			index: 37
		},
		{
			urlFull: '/gallery/complex/IMG_1280.webp',
			urlPreview: '/gallery/complex/IMG_1280-compressed.webp',
			index: 38
		},
		{
			urlFull: '/gallery/complex/IMG_1306.webp',
			urlPreview: '/gallery/complex/IMG_1306-compressed.webp',
			index: 39
		},
		{
			urlFull: '/gallery/complex/IMG_1309.webp',
			urlPreview: '/gallery/complex/IMG_1309-compressed.webp',
			index: 40
		}

	]
}