export const appHead = {"meta":[{"name":"msapplication-TileColor","content":"#c93"},{"name":"theme-color","content":"#ffffff"},{"charset":"utf-16"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"description","content":"Жилой комплекс бизнес-класса «Ривер Плаза» – это идеальное сочетание удачного расположения и самодостаточной инфраструктуры. Девятиэтажное здание распложено в самом центре Сочи, на закрытой территории со всем необходимым для жизни."}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","sizes":"32x32","type":"image/png","href":"/favicon-32x32.png"},{"rel":"icon","sizes":"16x16","type":"image/png","href":"/favicon-16x16.png"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","color":"#ffffff","href":"/safari-pinned-tab.svg"},{"key":"mdi","rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css","type":"text/css","crossorigin":"anonymous"}],"style":[],"script":[{"src":"https://mc.yandex.ru/metrika/tag.js","async":true,"tagPosition":"head"}],"noscript":[],"htmlAttrs":{"lang":"ru"},"title":"ЖК «Ривер Плаза» в Сочи | Купить квартиру от застройщика"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false